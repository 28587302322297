@import 'stylesheets/index.css';

:root {
  --theme-color: #3fec6f; /* 01F0A0 2DDFB6 3DD1FE #6bf442*/
}

ul {
  list-style: none;
}

h1, button {
  color: #fff;
}

h1, h2, h3, p, legend {
  cursor: default;
}

a {
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: none;
}

button {
  border-radius: 5px;
  padding: .5rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: transparent;
  vertical-align: middle;
}

i.fa-md {
  font-size: 1.2rem;
}

i.fa-sm {
  font-size: 1rem;
}

hr {
  width: 90%;
  border-top: 0.5px solid #f1f1f1;
}

.no-select, button, h1, h2, h3, p, a, span, nav, legend {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

canvas {
  display:block;
  position: fixed;
  z-index: -2;
}

.App-container {
  text-align: center;
  float: left;
  margin-left: 75px;
  width: 100%;
  height: 100%;
}

.input-wrapper {
  margin: 10px 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-button-right {
  margin-left: -1px;
  display: flex;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}

.container {
  margin-left: 100px;
}

.side-container {
  position: absolute;
  right: 0;
  padding-top: 130px;
}

/*TECH COLORS BEGIN*/
.icon-s {
  width: 15px;
  height: 15px;
}

.rails { color: #F46767; }
.react-js { color: #679FF4; }
.redux { color: #AD67F4; }
.node { color: #74D565; }
.express { color: #FEC508; }
.mongodb { color: #00B31E; }
.cloud { color: #5BD8FE; }
/*TECH COLORS END*/

.main-title {
  position: absolute;
  top: 15%;
  color: #FFF;
  text-align: left;
  font-family: 'Lobster Two', cursive;
  font-weight: 300;
  font-size:  58.86px;
}

.main-title>span {
  margin: 10px 0 10px 0;
  background: -webkit-linear-gradient(#dedede, #38495a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.main-title>span:hover {
  -webkit-transition: all ease-in-out;
  -moz-transition: all ease-in-out;
  -ms-transition: all ease-in-out;
  -o-transition: all ease-in-out;
  transition: all ease-in-out;
  color: transparent;
  text-shadow: 0 0 2px rgba(255,255,255,0.9), 2px 0px 0px #00FFD7, -1px 0px 0px #FF0000;
  padding: 0 0 0 -1px;
}

.home-subtext {
  display: table;
  margin: 0 auto;
  font-family: 'Josefin Sans', serif;
  font-weight: 400;
  font-size: 30px;
  background: linear-gradient(330deg, #e05252 0%, #99e052 25%, #52e0e0 50%, #9952e0 75%, #e05252 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 100px;
}

.subtext {
  margin: 40px 50px 20px 0;
  max-width: 600px;
  font-size: 18px;
  font-family: 'Josefin Sans', sans-serif;
  letter-spacing: 1px;
  word-spacing: 2px;
  line-height: 1.4;
  font-weight: 500;
}

.subcontent {
  position: absolute;
  top: 15%;
  margin-top: 120px;
  text-align: left;
}

/* FLEXBOX */
.box {
  display: flex;
  flex-wrap: wrap;
}

.rainbow-animation {
  color: #f35626;
  background-image: -webkit-linear-gradient(92deg,#f35626,#feab3a);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hue 10s infinite linear;
}

@keyframes hue {
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
}

@keyframes blowUpModal {
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
}

@-webkit-keyframes live {
  0%, 80%, 100% { -webkit-transform: scale(0.6) }
  40% { -webkit-transform: scale(1.0) }
}
@keyframes live {
  0%, 80%, 100% { 
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
  } 40% { 
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

@keyframes animateTextBackground {
  0% {
    background-position: 0 0;
  }
  25% {
    background-position: 100% 0;
  }
  50% {
    background-position: 100% 100%;
  }
  75% {
    background-position: 0 100%;
  }
  100% {
    background-position: 0 0;
  }
}