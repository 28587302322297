.add-projects-component {
  background-color: #b8daff;
  padding: 20px;
  text-align: left;
}
.project-input-group label {
  font-family: 'Ovo', serif;
  font-size: 25px;
  margin-right: 10px;
}
.project-input-group input {
  padding: 5px;
  font-size: 15px;
  border-radius: 3px;
  border: 1px solid #BDBDBD;
  width: 100%;
  margin-bottom: 10px;
}
.projects-form button {
  float: right;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  font-size: 15px;
  cursor: pointer;
}
.project-input-note {
  color: #856404;
}
.project-submit-btn {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.project-clearImage-btn {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db;
}
