.form-container {
  font-size: 18px;
  font-family: 'Josefin Sans', serif;
  width: 75vw;
  letter-spacing: 1px;
  margin-top: 40px;
}

.contact-form {
  margin: 0 auto;
  color: #b5e3ff;
}

.contact-form fieldset {
  border-radius: 5px;
  background-color: #00183194;
  border-color: #03569e8c;
  box-shadow: 7px 8px 15px -3px rgba(104, 158, 255, 0.54), 
              1px 1px 6px -2px rgba(189, 215, 255, 0.05);
}

.contact-form label {
  font-weight: 700;
  cursor: pointer;
}

.contact-form textarea,
.contact-form input {
  border: none;
  outline: none;
  border-radius: 0;
  text-align: center;
  background: none;
  font-family: 'Josefin Sans', serif;
  font-size: 16px;
  color: #b5e3ff;
  max-width: 90%;
  min-width: 170px;
  border: 2px dashed rgba(255, 255, 255, 0);
  box-sizing: border-box;
  cursor: text;
}

.contact-form textarea::placeholder,
.contact-form input::placeholder {
  letter-spacing: 1px;
}

.contact-form textarea {
  text-align: left;
  /* overflow:hidden; */
  resize: none;
  width: 90%;
  height: 20vh;
  border-color: rgba(255, 255, 255, 0)
}

.contact-form textarea:focus:required:valid {
  border: 2px solid rgba(255, 255, 255, 0);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.contact-form textarea:focus,
.contact-form input:focus {
  border-bottom: 2px dashed rgba(255, 255, 255, 1);
  background-color: rgba(255, 255, 255, 0.2);
}

.contact-form textarea:required:valid,
.contact-form input:required:valid {
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.contact-form input:required:invalid {
  color: #FF3D3D;
}

.expanding {
  vertical-align: top;
}

.contact-btn-wrapper {
  width: 130px;
}

.contact-btn-wrapper span {
  font-size: 15px;
}

.contact-btn  {
  color: #FFF;
  border-radius: 2px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  padding: .2rem;
  letter-spacing: 2px;
}

.contact-btn .contact-btn-text {
  position: relative;
  left: 10px;
  bottom: 5px;
}

.contact-btn .contact-btn-send {
  display: none;
}

.contact-btn i {
  left: 5px;
  font-size: 20px;
  color: #FFF;
  z-index: 10;
  transition:all 0.2s;
}

.contact-btn:not(.contact-btn-send):hover i {
  transform:rotateZ(20deg);
}

.contact-btn.contact-btn-send .contact-btn-text {
  transition:all 0.5s;
  display:none;
}

.contact-btn.contact-btn-send .contact-btn-send {
  opacity: 1;
  display: inline-block;
  position: relative;
  left: 25%;
  bottom: 5px;
  transform: translateX(-50%);
}

.contact-btn.contact-btn-send .contact-btn-icon {
  animation: flysend 2s;
  position: relative;
  bottom: 5px;
}

.contact-btn-icon i.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.contact-btn.contact-btn-send i {
  top: 5px;
  animation: flyspin 2s;
}

@keyframes flyspin {
  0%{transform: rotateZ(-20deg);}
  75%{transform: rotateZ(40deg);}
  100%{transform: rotateZ(40deg);}
}

@keyframes flysend {
  0%{left: 20px;}
  75%{left: -100px;}
  100%{left: 100px;}
}

@keyframes blink {
  0%{opacity: 0;}
  50%{opacity: 1;}
  100%{opacity: 0;}
}
