/* Past fonts 
  Titillium+Web
  Inconsolata
  Dosis
  Vollkorn:400,700,900
*/
@import url("https://fonts.googleapis.com/css?family=Lobster+Two|Inconsolata|Josefin+Sans:300,500,700&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: 'Josefin Sans', serif;
  background-color: #191A1A;
}
