.social-icons {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.social-icon {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
			-ms-flex-align: center;
					align-items: center;
	-webkit-box-pack: center;
			-ms-flex-pack: center;
					justify-content: center;
	position: relative;
	width: 30px;
	height: 30px;
	margin: 0 0.5rem;
	border-radius: 50%;
	cursor: pointer;
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
	font-size: 2.5rem;
	text-decoration: none;
	text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
	-webkit-transition: all 0.15s ease;
	transition: all 0.15s ease;
}

.social-icon:hover {
	color: #fff;
}

.social-icon:hover .tooltip {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translate(-50%, -150%);
					transform: translate(-50%, -150%);
}

.social-icon:active {
	box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.5) inset;
}

.social-icon--linkedin {
	background: #006599;
	background: -webkit-linear-gradient(#0d6d9e, #006091);
	background: linear-gradient(#0d6d9e, #006091);
	border-bottom: 1px solid #00517a;
	color: #ffffff;
}
.social-icon--linkedin:hover {
	color: #add4e8;
	text-shadow: 0px 1px 0px #00517a;
}
.social-icon--linkedin .tooltip {
	background: #006599;
	background: -webkit-linear-gradient(#267ca8, #006599);
	background: linear-gradient(#267ca8, #006599);
	color: #ffffff;
}
.social-icon--linkedin .tooltip:after {
	border-top-color: #006599;
}

.social-icon--twitter {
	background: #2b97f1;
	background: -webkit-linear-gradient(#369cf2, #298fe5);
	background: linear-gradient(#369cf2, #298fe5);
	border-bottom: 1px solid #2279c1;
	color: #ffffff;
}
.social-icon--twitter:hover {
	color: #cbe8ff;
	text-shadow: 0px 1px 0px #2279c1;
}
.social-icon--twitter .tooltip {
	background: #2b97f1;
	background: -webkit-linear-gradient(#4ba7f3, #2b97f1);
	background: linear-gradient(#4ba7f3, #2b97f1);
	color: #ffffff;
}
.social-icon--twitter .tooltip:after {
	border-top-color: #2b97f1;
}

.social-icon--facebook {
	background: #3b5a9b;
	background: -webkit-linear-gradient(#4562a0, #385693);
	background: linear-gradient(#4562a0, #385693);
	border-bottom: 1px solid #2f487c;
	color: #9dadcd;
}
.social-icon--facebook:hover {
	color: #d8deeb;
	text-shadow: 0px 1px 0px #2f487c;
}
.social-icon--facebook .tooltip {
	background: #3b5a9b;
	background: -webkit-linear-gradient(#5873aa, #3b5a9b);
	background: linear-gradient(#5873aa, #3b5a9b);
	color: #d8deeb;
}
.social-icon--facebook .tooltip:after {
	border-top-color: #3b5a9b;
}

.social-icon--github {
	background: #2e2f30;
	border-bottom: 1px solid #2e2f30;
	color: #ffffff;
}
.social-icon--github:hover {
	color: #d0d0d0;
	text-shadow: 0px 1px 0px gray;
}
.social-icon--github .tooltip {
	background: #2e2f30;
	background: -webkit-linear-gradient(#2e2f30, #2e2f30);
	background: linear-gradient(#2e2f30, #2e2f30);
	color: #ffffff;
}
.social-icon--github .tooltip:after {
	border-top-color: #2e2f30;
}

.social-icon--instagram {
	background: #fd5949;
	background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
	border-bottom: 1px solid #00517a;
	color: #ffffff;
}
.social-icon--instagram:hover {
	color: #ffffff;
	text-shadow: 0px 1px 0px #00517a;
}
.social-icon--instagram .tooltip {
	background: #fd5949;
	background: -webkit-radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
	background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
	color: #ffffff;
}
.social-icon--instagram .tooltip:after {
	border-top-color: #fd5949;
}

.social-icon--gmail {
	background: #ff2c2c;
	background: -webkit-linear-gradient(#ff2c2c, #ff2c2c);
	background: linear-gradient(#ff2c2c, #ff2c2c);
	border-bottom: 1px solid #ff2c2c;
	color: #ffffff;
}
.social-icon--gmail:hover {
	color: #ffe5e5;
	text-shadow: 0px 1px 0px #ff2c2c;
}
.social-icon--gmail .tooltip {
	text-transform: lowercase;
	background: #ff2c2c;
	background: -webkit-linear-gradient(#ff2c2c, #ff2c2c);
	background: linear-gradient(#ff2c2c, #ff2c2c);
	color: #ffffff;
}
.social-icon--gmail .tooltip:after {
	border-top-color: #ff2c2c;
}

.social-icon i {
	position: relative;
	font-size: 18px;
}

/* Tooltips */
.tooltip {
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	padding: 0.8rem 1rem;
	border-radius: 3px;
	font-size: 0.8rem;
	font-weight: bold;
	opacity: 0;
	pointer-events: none;
	text-transform: uppercase;
	-webkit-transform: translate(-50%, -100%);
					transform: translate(-50%, -100%);
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	z-index: 1;
}
.tooltip:after {
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0;
	height: 0;
	content: "";
	border: solid;
	border-width: 10px 10px 0 10px;
	border-color: transparent;
	-webkit-transform: translate(-50%, 100%);
					transform: translate(-50%, 100%);
}
