ul.tabs {
  position: relative;
  left: 100px;
	height: 80px;
  margin: 0;
	list-style: none;
	overflow: hidden;
  padding: 0;
  max-width: calc(80% - 100px);
}
ul.tabs li {
	float: left;
	width: 130px;
}
ul.tabs li span {
	position: relative;
	display: block;
	height: 30px;
	margin-top: 40px;
	padding: 13px 0 0 0;
	font-family: 'Ovo', serif;
	font-size: 15px;
	text-align: center;
	text-decoration: none;
  cursor: pointer;
	color: #ffffff;
	background: #6487C1;
	-webkit-box-shadow: 8px 12px 25px 2px rgba(0,0,0,0.4);
	   -moz-box-shadow: 8px 12px 25px 2px rgba(0,0,0,0.4);
	        box-shadow: 8px 12px 25px 2px rgba(0,0,0,0.4);
	        	border: 0px solid #000000;
	-webkit-transition: padding 0.2s ease, margin 0.2s ease;
       -moz-transition: padding 0.2s ease, margin 0.2s ease;
         -o-transition: padding 0.2s ease, margin 0.2s ease;
        -ms-transition: padding 0.2s ease, margin 0.2s ease;
            transition: padding 0.2s ease, margin 0.2s ease;
}
.tabs li:first-child span {
	z-index: 3;
  -webkit-border-top-left-radius: 8px;
-moz-border-radius-topleft: 8px;
border-top-left-radius: 8px;
}
.tabs li:nth-child(2) span {
	z-index: 2;
}
.tabs li:last-child span {
	z-index: 1;
  -webkit-box-shadow: 2px 8px 25px -2px rgba(0,0,0,0.3);
	   -moz-box-shadow: 2px 8px 25px -2px rgba(0,0,0,0.3);
	        box-shadow: 2px 8px 25px -2px rgba(0,0,0,0.3);
  -webkit-border-top-right-radius: 8px;
-moz-border-radius-topright: 8px;
border-top-right-radius: 8px;
}
ul.tabs li span:hover {
	margin: 35px 0 0 0;
	padding: 10px 0 5px 0;
}
ul.tabs li span.active {
	margin: 30px 0 0 0;
	padding: 15px 0 10px 0;
	background: #545f60;
	color: #6edeef;
	/*color: #ff6831;*/
	z-index: 4;
	outline: none;
  font-size: 17px;
}
.group:before,
.group:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}
.group:after {
    clear: both;
}
