.messages-container {
  border: 1px solid #327a81;
  box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  overflow: scroll;
  width: 100%;
}

.message-table {
  width: 100%;
  font-family: 'Ovo', serif;
}

.message-table td, .message-table th {
  color: #2b686e;
  padding: 10px;
}
.message-table td {
  text-align: left;
  vertical-align: middle;
  cursor: default;
}
.message-table td:last-child {
  font-size: 0.95em;
  line-height: 1.4;
}
.message-table th {
  background-color: #DAE4F1;
  font-weight: 300;
}
.message-table tr:nth-child(2n) {
  background-color: white;
}
.message-table tr:nth-child(2n+1) {
  background-color: #edf7f8;
}
.message-table tr:hover {
  background-color: #DAE4F1;
}

@media screen and (max-width: 700px) {
  .message-table, tr, td {
    display: block;
  }

  td:first-child {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 100px;
  }
  td:not(:first-child) {
    clear: both;
    margin-left: 100px;
    padding: 4px 20px 4px 90px;
    position: relative;
    text-align: left;
  }
  td:not(:first-child):before {
    color: #91ced4;
    content: '';
    display: block;
    left: 0;
    position: absolute;
  }
  td:nth-child(2):before {
    content: 'Name:';
  }
  td:nth-child(3):before {
    content: 'Email:';
  }
  td:nth-child(4):before {
    content: 'Phone:';
  }
  td:nth-child(5):before {
    content: 'Comments:';
  }

  tr {
    padding: 10px 0;
    position: relative;
  }
  tr:first-child {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  td:first-child {
    background-color: #c8e7ea;
    border-bottom: 1px solid #91ced4;
    border-radius: 10px 10px 0 0;
    position: relative;
    top: 0;
    -webkit-transform: translateY(0);
            transform: translateY(0);
    width: 100%;
  }
  td:not(:first-child) {
    margin: 0;
    padding: 5px 1em;
    width: 100%;
  }
  td:not(:first-child):before {
    font-size: .8em;
    padding-top: 0.3em;
    position: relative;
  }
  td:last-child {
    padding-bottom: 1rem !important;
  }

  tr {
    background-color: white !important;
    border: 1px solid #6cbec6;
    border-radius: 10px;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
    margin: 0.5rem 0;
    padding: 0;
  }

  .advent-container {
    border: none;
    box-shadow: none;
    overflow: visible;
  }
}
