@media screen and (max-width: 1640px) {
  .atom { width: 450px; }
}

@media screen and (max-width: 1380px) {
  .atom { z-index: -1; opacity: 0.2; }
}

@media screen and (max-width: 1180px) {
  .side-container {
    z-index: -1;
    opacity: 0.2;
  }
  .timeline-description { font-size: 10px; }
}

@media screen and (max-width: 1000px) {
  .home-container { margin-left: 80px; }
  .subtext { font-size: 16px; }
}

@media screen and (max-width: 600px) {
  body { overflow: visible; }
  .App-container { margin-left: 0px;}
  .side-container {
    left: 0;
    bottom: 0;
    opacity: 1;
  }

  .main-title {
    font-size: 50px;
    left: 10%;
    top: 150px;
    right: 0;
  }

  .home-subtext,
  .subtext {
    width: 90%;
    padding-left: 0px;
  }

  .subcontent {
    margin: 80px 50px 1000px 0;
    left: 10%;
  }

  .side-container {
    margin-bottom: 50px;
    top: 300px;
    left: -10px;
  }

  .envelope-image {
    left: 43%;
  }

  /*CONTACTS*/
  .form-container { font-size: 15px; }
  .contact-form textarea,
  .contact-form input {
    font-size: 15px;
    min-width: 135px;
  }

  /*SOCIAL*/
  footer {
    height: 25px;
    top: 120px;
    left: 10%;
  }

  .social-icon {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }

  .social-icon i {
    font-size: 10px;
  }

  .tooltip {
    font-size: 8px;
  }

  /* .projects-container {
    left: 10%;
  } */

  #theme-button-wrapper {
    top: 75px;
  }
}

@media screen and (max-width: 500px) {
  .atom {
    right: 0;
    left: 10px;
    font-size: 10px;
    width: 100px;
  }
  .main-title { font-size: 40px; }
  .home-subtext { font-size: 25px; }
}

@media screen and (max-width: 400px) {
  .skill-set { min-width: 300px; }
  .timeline-description { margin-left: 2px; }
  .timeline-date{ margin-right: 20px; }
  .timeline-title{ margin-left: 100px; }
  .main-title { font-size: 35px; }
  .home-subtext { font-size: 20px; }
  .shadow { width: 300px; }
}
