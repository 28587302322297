.loading-bar-outer {
  position: absolute;
  top: 40%;
  width: 500px;
  height: 16px;
  background-color: #c5c5c5;
  border-radius: 16px;
  padding: 4px;
}

.loading-bar-inner {
  background-color: #f5f5f5;
  width: 10%;
  height: 100%;
  border-radius: 16px;
  animation-name: increase;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
} 

@keyframes increase {
  from {
    width: 1%
  }
  to {
    width: 100%
  }
}