#theme-button-wrapper {
    display:flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 10px;
    top: 10px;
}

#theme-button {
    height: 1.5rem;
    width: 4rem;
    border-radius: 2rem;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}

.theme-button-checkbox {
    visibility: hidden;
}

.theme-button-checkbox:checked ~ .light {
    transform: translateY(-50%) scale(0);
}

.theme-button-checkbox:checked ~ .dark {
    transform: translateY(-50%) scale(1);
    z-index: 10;
}

.dark, .light {
    height: 1rem;
    width: 1rem;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    top: 50%;
}
  
.light {
    background: #324047;
    left: 10%;
    z-index: 10;
    transform: translateY(-50%) scale(1);
    transition: all 200ms cubic-bezier(0.8, 0.7, 0.72, 0.78);
}

.dark {
    background: #FCD783;
    right: 10%;
    transform: translateY(-50%) scale(0);
    transition: all 200ms cubic-bezier(0.8, 0.7, 0.72, 0.78);
}
  